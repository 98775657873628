import React, { useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import map from 'lodash/map'

import Layout from '@c/layout'
import ResourceBlogPost from '@c/resource-blog-post'
import Breadcrumb from '@c/breadcrumb'
import makeSlug from '@u/make-slug'
import Selectable from '@/components/selectable'
import { Link } from 'gatsby'
import withLocation from '@/withLocation'
import QuickSearch from '@c/quick-search'
import Pagination from '@c/pagination'
import quickSearchBuilder, {
  QuickSearchMapping
} from '@c/quick-search/quick-search-builder'

import './index.css'
import usePaginator from '@/hooks/resource/use-paginator'
import classNames from 'classnames'
import addCategoryTitleToPage from '../add-category-title-to-page'
import addPageNumberToPage from '../add-page-number-to-page'

function makeResourceBlogPostProps(guide, genSlug, categoryBase) {
  return {
    categoryBase: categoryBase,
    categories: guide?.category ? [guide.category] : [],
    title: guide.title,
    url: genSlug(guide.slug),
    cover: guide.image,
    authorNickname: guide.author?.nickname,
    authorName: `${guide.author?.firstName} ${guide.author?.lastName}`,
    authorPhoto: guide.author?.profileImage
  }
}

const Page = ({ data, pageContext }) => {
  const { pageSettings } = data
  const {
    resourcesSlug,
    resourcesTitle,
    resourcesGuideSlug,
    resourcesGuideTitle,
    categorySlug
  } = pageSettings
  const {
    _allResourcesSlugLocales,
    _allResourcesGuideSlugLocales
  } = pageSettings
  const title = resourcesGuideTitle
  const locale = pageContext.locale

  // Prepare the paginator
  const paginator = usePaginator({
    currentPage: pageContext.currentPage,
    totalPages: pageContext.numPages
  })

  // Map posts from graphql result
  const posts = useMemo(() => data.posts.nodes)
  // Filter categories that doesnt have any guides
  const categories = data.categories.nodes.filter(o =>
    data.usedCategories.distinct.includes(o.slug)
  )
  // Check if this list is categorized
  const currentCategory =
    (pageContext?.category &&
      categories.find(o => o.slug === pageContext?.category)) ||
    null
  // Set the text of category selectable
  const categoryText = currentCategory
    ? currentCategory.title
    : data?.datoCmsTranslation?.filteringAll
  // Create breadcrumb base
  const parentPages = [
    {
      title: resourcesTitle,
      url: makeSlug.generic(pageContext.locale, resourcesSlug)
    }
  ]

  const genSlug = useCallback(
    function genSlug(slug) {
      return makeSlug.generic(
        pageContext.locale,
        resourcesSlug,
        resourcesGuideSlug,
        slug
      )
    },
    [pageContext, resourcesSlug, resourcesGuideSlug]
  )
  const paginatorSlug = genSlug(
    currentCategory ? `${categorySlug}/${currentCategory.slug}` : ''
  )
  // If this is a categorized list then add uncategorized link as a breadcrumb parent
  if (currentCategory) {
    parentPages.push({
      title: resourcesGuideTitle,
      url: genSlug('')
    })
  }

  // Prepare the search list for quick search
  const searchList = map(data.searchList.edges, 'node')
  const searchMappings = quickSearchBuilder()
    .addCriteria(
      new QuickSearchMapping(searchList, item =>
        makeSlug.generic(locale, resourcesSlug, resourcesGuideSlug, item?.slug)
      )
    )
    .build()
  const fnCreateSeoMeta = () => {
    const locales = _allResourcesSlugLocales.map(o => o.locale)
    const r = []
    const fnGetByLang = (allLocale, locale) =>
      allLocale?.find(o => o.locale === locale)?.value
    locales.forEach(element => {
      const slugChain = [
        fnGetByLang(_allResourcesSlugLocales, element),
        fnGetByLang(_allResourcesGuideSlugLocales, element)
      ]
      r.push({
        rel: 'alternate',
        href: makeSlug.generic(element, ...slugChain),
        hreflang: element
      })
    })
    return {
      link: r,
      title: addPageNumberToPage(
        addCategoryTitleToPage(title, currentCategory),
        locale,
        paginator
      )
    }
  }
  const fnMakeCategorizedSlug = item =>
    genSlug(item?.category?.slug ? `${categorySlug}` : '')
  return (
    <Layout
      locale={pageContext.locale}
      seo={{
        ...fnCreateSeoMeta()
      }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-40">
        <div className="row align-items-center">
          <div className="col-12 col-lg-auto">
            <Breadcrumb
              className={currentCategory ? '' : 'resource-breadcrumb'}
              parentPages={parentPages}
              currentPage={currentCategory?.title || title}
              wrapCurrentPageWithH1={true}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-4" style={{ marginBottom: '16px' }}>
            <Selectable text={categoryText} style={{ zIndex: 2 }}>
              <Link to={genSlug('')}>
                {data?.datoCmsTranslation?.filteringAll}
              </Link>
              {categories.map((o, key) => (
                <Link key={key} to={genSlug(`${categorySlug}/${o.slug}`)}>
                  {o.title}
                </Link>
              ))}
            </Selectable>
          </div>
          <div
            className={classNames(
              'col-12 d-flex flex-column align-items-center',
              paginator.result.totalPages > 1 ? 'col-lg-auto' : 'col-lg-4'
            )}
          >
            <QuickSearch
              mappings={searchMappings}
              style={{ marginBottom: '16px', zIndex: 1, minWidth: 'auto' }}
            />
            <Pagination paginator={paginator} baseSlug={paginatorSlug} />
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-lg-end"></div>
      </div>
      {/* ALL */}
      <div className="container">
        <div className="row mt-40">
          {posts.map(post => (
            <div key={post?.slug} className="col-12 col-lg-6">
              <ResourceBlogPost
                key={post.id}
                locale={locale}
                {...makeResourceBlogPostProps(
                  post,
                  genSlug,
                  fnMakeCategorizedSlug(post)
                )}
              >
                {post.descriptionSmall}
              </ResourceBlogPost>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <Pagination paginator={paginator} baseSlug={paginatorSlug} />
        </div>
      </div>
    </Layout>
  )
}

export default withLocation(Page)

export const query = graphql`
  query ResourcesGuideQuery(
    $locale: String
    $skip: Int!
    $limit: Int!
    $filter: DatoCmsGuideFilterInput!
  ) {
    datoCmsTranslation(locale: { eq: $locale }) {
      filteringAll
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        slug
        _allSlugLocales {
          locale
          value
        }
      }
    }
    usedCategories: allDatoCmsGuide(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      distinct(field: category___slug)
    }
    searchList: allDatoCmsGuide(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          title
          slug
          locale
        }
      }
    }
    posts: allDatoCmsGuide(
      filter: $filter
      limit: $limit
      skip: $skip
      sort: { fields: [meta___firstPublishedAt], order: DESC }
    ) {
      nodes {
        _allSlugLocales {
          locale
          value
        }
        id
        locale
        title
        slug
        descriptionSmall
        category {
          slug
          title
        }
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }

        author {
          firstName
          lastName
          nickname
          profileImage {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 30)
                }
              }
            }
          }
        }
      }
    }

    # page settings
    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesGuideSlugLocales {
        locale
        value
      }
      _allCategorySlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesGuideTitle
      resourcesGuideSlug
      categorySlug
    }

    # translation
    translations: datoCmsTranslation(locale: { eq: $locale }) {
      guideListTitleFirst
      guideListTitleSecond
    }
  }
`
